/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import { object, shape, string } from 'prop-types';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { GRAPHQL_ENV } from '../../gql';
import { getBrand } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';

const contType = 'promo_messaging';

// eslint-disable-next-line react/prefer-stateless-function
class PromoQuery extends Component {
    render() {
        const { promocode, brand } = this.props;
        const PROMO_QUERY = gql`{
            findContent(brand: "${brand.domain}", environment: "${GRAPHQL_ENV}", contentType: "${contType}", query:"{\\"promotion_code\\" : \\"${promocode}\\"}")
            { content }
        }  
        `;
        return (
            <Query query={PROMO_QUERY}>
                {({ loading, error, data }) => {
                    if (loading) {
                        // TODO: Add spinner graphic
                        return <ReactMarkdown source="loading..." />;
                    }
                    if (error) {
                        return <ReactMarkdown source="There has been an error retrieving details for this promotion. If this issue persists, please contact us." />;
                    }
                    if (data) {
                        const content = data?.findContent?.content?.entries?.[0]?.promotion_details || `## Error finding details for promo code ${promocode}.  Promo has expired or has been changed.`;
                        return <ReactMarkdown source={content} escapeHtml={false} />;
                    }
                    return <>loading...</>;
                }}

            </Query>
        );
    }
}
const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

PromoQuery.propTypes = {
    promocode: shape({
        promotion_code: string,
        promotion_details: string,
        title: string,
    }).isRequired,
    brand: object.isRequired,
};

export default connect(mapStateToProps)(PromoQuery);
