/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const ifExternalUrl = (e, url, openInSameTab) => {
    if ((url.includes('https://') || url.includes('http://')) && typeof window !== 'undefined') {
        e.preventDefault();
        window.open(url, openInSameTab ? '_self' : null);
    }
};

export const elementWidth = (data, dividerWidth = 0) => {
    // Base element widths on number of rows.
    // E.g. 10 items across 1 row = 10% width (+/-) each
    //      10 items across 2 rows = 20% width (+/-) each
    // if 1 row and two elements, create narrower gap between both thatn ususal.
    const elementCount = data?.reference?.length || 0;
    const panelWidthOverride = data?.panel_width_override;
    const rowCount = data?.row_count || 1;
    let widthValue = 0;
    let adjustDivider = 0;

    if (rowCount > 1) { // more than one row, distribute elements to rows by making widths relative to row count
        widthValue = `${98 / (elementCount / rowCount)}%`;
        adjustDivider = (dividerWidth / rowCount) / elementCount;
    }

    if (rowCount === 1) { // only one row, make widths even
        widthValue = `${98 / elementCount}%`;
        adjustDivider = dividerWidth / elementCount;
    }

    if (elementCount === 2) { // only two elements, make gap small
        widthValue = `${99.5 / elementCount}%`;
        adjustDivider = dividerWidth / elementCount;
    }
    if (panelWidthOverride) { // When We set width value in CMS then width override with CMS width
        widthValue = `${panelWidthOverride}%`;
        adjustDivider = dividerWidth / elementCount;
    }
    return { width: `calc(${widthValue} - ${adjustDivider}px)` };
};
