/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    string, bool, func,
} from 'prop-types';
// import ButtonBase from '@material-ui/core/ButtonBase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { trackEvent as track } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { ifExternalUrl } from '../NUp/helper';
// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: 'none',
        background: 'none',
        border: 'none',
    },
    button: (styleData) => ({
        width: 'fit-content',
        fontSize: '18px',
        fontFamily: `${parseFont(styleData.fontFamily) || 'Crimson, Garamond, Times, serif'}`,
        padding: '8px 20px 9px',
        color: `${styleData.fontColor || theme.palette.cms?.primary || theme.palette.colorPrimary}`,
        backgroundColor: styleData.backgroundColor || theme.palette.white,
        borderRadius: styleData.addRadius ? '4px' : '0',
    }),
    border: (styleData) => ({
        display: 'flex',
        border: `1px solid ${styleData.fontColor || theme.palette.cms?.primary || theme.palette.colorPrimary}`,
    }),
    chevron: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '28px',
        marginLeft: '3px',
        marginRight: '-8px',
        paddingTop: '2px',
    },
}));

const CTA = ({
    href,
    title,
    fontColor,
    backgroundColor,
    copy,
    fontFamily,
    disable_chevron,
    border,
    tracking_event_category,
    className,
    tracking_event_action,
    tracking_event_label,
    trackEvent,
    addRadius,
    inert,
}) => {
    const styleData = {
        fontColor, backgroundColor, fontFamily, addRadius,
    };
    const history = useHistory();
    const classes = useStyles(styleData);
    return (
    // If there is no href defined we can assume this is a decorative button.
    // This will avoid nested anchor tags in the rendered content

        !href || inert ? (
            <div
                className={`${className} ${classes.button} ${border && classes.border}`}
                data-testid="cta-decorative"
            >
                {copy}{!disable_chevron && <ChevronRightIcon className={classes.chevron} />}
            </div>
        ) : (
            <div
                role="link"
                data-ga-category={tracking_event_category || ''}
                data-ga-action={tracking_event_action || ''}
                data-ga-label={tracking_event_label || ''}
                className={`${classes.link} ${className}`}
                onClick={(e) => {
                    trackEvent({
                        eventCategory: tracking_event_category || '',
                        eventAction: tracking_event_action || '',
                        eventLabel: tracking_event_label || '',
                    });
                    ifExternalUrl(e, href, true); // this breaks in unit tests and doesn't work on right click, open in new tab
                    history.push(href);
                }}
                onKeyDown={() => {}}
                tabIndex="0"
                title={title}
                data-testid="cta-anchor"
            >
                <div
                    className={`${classes.button} ${border && classes.border}`}
                >
                    {copy}{!disable_chevron && <ChevronRightIcon className={classes.chevron} />}
                </div>
            </div>
        )
    );
};

CTA.propTypes = {
    href: string,
    title: string,
    fontColor: string,
    copy: string,
    fontFamily: string,
    backgroundColor: string,
    disable_chevron: bool,
    border: bool,
    tracking_event_category: string,
    tracking_event_action: string,
    tracking_event_label: string,
    trackEvent: func.isRequired,
    className: string,
    addRadius: bool,
    inert: bool,
};
CTA.defaultProps = {
    href: null,
    title: null,
    tracking_event_category: null,
    tracking_event_action: null,
    tracking_event_label: null,
    fontFamily: '',
    backgroundColor: '#ffffff',
    copy: 'Shop Now',
    fontColor: '#333333',
    className: '',
    disable_chevron: false,
    border: true,
    addRadius: false,
    inert: false,
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});
const enhance = compose(
    connect(null, mapDispatchToProps),
);
export default enhance(CTA);
